import React, { PropsWithChildren, useRef, useState } from 'react';
import {
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
} from '@backstage/core-components';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { usePermission } from '@backstage/plugin-permission-react';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import CategoryIcon from '@mui/icons-material/Category';
import ExtensionIcon from '@mui/icons-material/Extension';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HelpIcon from '@mui/icons-material/Help';
import SidebarLogo from './SideBarLogo';
import {
  SidebarSubmenu,
  submenuOptions,
  SUBMENU_OPTIONS,
  CREATE_NEW_SIDEBAR_OPTIONS,
  SUPPORT_SIDEBAR_OPTIONS,
  SUBMENU_TITLES,
} from '../sidebar-submenu/';
import { XelerateLogoIconMono } from '../Icons/icons';
import BuildIcon from '@mui/icons-material/Build';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { RequirePermission } from '@backstage/plugin-permission-react';

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { loading: loadingPermission, allowed: canCreateEntity } =
    usePermission({
      permission: catalogEntityCreatePermission,
    });
  const supportSubmenuRef = useRef<HTMLElement>();
  const createSubmenuRef = useRef<HTMLElement>();
  const supportSubmenuPopupRef = useRef<HTMLElement>();
  const createSubmenuPopupRef = useRef<HTMLElement>();
  const [submenuPopupOpen, setSubmenuPopupOpen] = useState<submenuOptions>(
    SUBMENU_OPTIONS.none,
  );
  const [isSubmenuHoveredOn, setIsSubmenuHoveredOn] = useState<submenuOptions>(
    SUBMENU_OPTIONS.none,
  );

  // Close the popup when pointer leaves the popup or anchor element
  const handlePointerLeave = (event: PointerEvent) => {
    // this is true if the pointer is moving from the sidebar to the popup
    // in this case we don't want to close the popup
    if (
      supportSubmenuPopupRef.current?.contains(event.relatedTarget as Node) ||
      createSubmenuPopupRef.current?.contains(event.relatedTarget as Node)
    ) {
      return;
    }
    setSubmenuPopupOpen(SUBMENU_OPTIONS.none);
  };

  // handles the opening of the popup
  // if the sidebar is closed, the popup will open after the animation ends
  // if the sidebar is open, the popup will open immediately
  const handleAnimation = (elem: HTMLElement | null, kind: submenuOptions) => {
    if (!elem) return;

    // check if the sidebar is closed...
    const drawerClosed = elem.className.includes('BackstageSidebarItem-closed');

    if (!drawerClosed) {
      if (kind === SUBMENU_OPTIONS.create)
        setSubmenuPopupOpen(SUBMENU_OPTIONS.create);
      else setSubmenuPopupOpen(SUBMENU_OPTIONS.support);
      return;
    }

    // ...if it is, wait for the animation to end before opening the popup
    elem.addEventListener(
      'animationend',
      () => {
        if (kind === SUBMENU_OPTIONS.create)
          setSubmenuPopupOpen(SUBMENU_OPTIONS.create);
        else setSubmenuPopupOpen(SUBMENU_OPTIONS.support);
      },
      { once: true },
    );
  };

  // Open the popup when clicking the support button
  const handleClickSubmenu = (
    event: React.MouseEvent<Element, MouseEvent>,
    kind: submenuOptions,
  ) => {
    const elem = event.currentTarget as HTMLElement;
    elem.onpointerleave ||= handlePointerLeave;

    if (kind === SUBMENU_OPTIONS.create) {
      createSubmenuRef.current = elem;
    } else {
      supportSubmenuRef.current = elem;
    }
    if (submenuPopupOpen !== SUBMENU_OPTIONS.none) {
      setSubmenuPopupOpen(SUBMENU_OPTIONS.none);
      setIsSubmenuHoveredOn(SUBMENU_OPTIONS.none);
    } else {
      handleAnimation(elem, kind);
      if (kind === SUBMENU_OPTIONS.create)
        setIsSubmenuHoveredOn(SUBMENU_OPTIONS.create);
      else setIsSubmenuHoveredOn(SUBMENU_OPTIONS.support);
    }
  };

  const handlePopupEnterSubmenu = (
    event: React.PointerEvent<HTMLElement> | undefined,
    kind: submenuOptions,
  ) => {
    if (kind === SUBMENU_OPTIONS.create) {
      createSubmenuPopupRef.current = event?.target as HTMLElement;
      setIsSubmenuHoveredOn(SUBMENU_OPTIONS.create);
    } else {
      supportSubmenuPopupRef.current = event?.target as HTMLElement;
      setIsSubmenuHoveredOn(SUBMENU_OPTIONS.support);
    }
  };

  const handlePopupLeaveSubmenu = () => {
    setSubmenuPopupOpen(SUBMENU_OPTIONS.none);
    setIsSubmenuHoveredOn(SUBMENU_OPTIONS.none);
  };

  const handlePopupClickAwaySubmenu = () => {
    setSubmenuPopupOpen(SUBMENU_OPTIONS.none);
    setIsSubmenuHoveredOn(SUBMENU_OPTIONS.none);
  };

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          {/* Changing to icon={() => <IconCompoennt />} because of known issue with mui 5 upgrade.
          This is an aletrnative that solves it. Reference: https://github.com/backstage/backstage/issues/18018*/}
          {!loadingPermission && canCreateEntity && (
            <SidebarSubmenu
              kind={SUBMENU_OPTIONS.create}
              title={SUBMENU_TITLES.CREATE}
              Icon={AddCircleIcon}
              sidebarOptions={CREATE_NEW_SIDEBAR_OPTIONS}
              isHoveredOn={isSubmenuHoveredOn === SUBMENU_OPTIONS.create}
              submenuRef={createSubmenuRef}
              popupOpen={submenuPopupOpen === SUBMENU_OPTIONS.create}
              highlighted
              onSetIsHoveredOn={setIsSubmenuHoveredOn}
              onHandleClick={handleClickSubmenu}
              onHandlePopupClickAway={handlePopupClickAwaySubmenu}
              onHandlePopupEnter={e =>
                handlePopupEnterSubmenu(e, SUBMENU_OPTIONS.create)
              }
              onHandlePopupLeave={handlePopupLeaveSubmenu}
            />
          )}
          <SidebarItem icon={() => <HomeIcon />} to="/" text="Home" />
          <SidebarItem
            icon={() => <CategoryIcon />}
            to="/catalog"
            text="Catalog"
          />
          <SidebarItem
            icon={() => <CategoryIcon />}
            to="/catalog-ver-2"
            text="Service Catalog"
          />
          <SidebarItem
            icon={() => <ExtensionIcon />}
            to="api-docs"
            text="APIs"
          />
          <SidebarItem icon={() => <LibraryBooks />} to="docs" text="Docs" />
          <SidebarItem
            icon={() => <LibraryBooks />}
            to="/create"
            text="Templates"
          />
          {/* {!loadingPermission && canCreateEntity && (
            <SidebarItem
              icon={() => <CreateComponentIcon />}
              to="catalog-import"
              text="Add..."
            />
          )} */}
          {/* Original create button
          {!loadingPermission && canCreateEntity && (
            <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
          )}
        */}
          {/* End global nav */}
          <SidebarDivider />
          <SidebarItem
            icon={() => <XelerateLogoIconMono />}
            to="/about"
            text="About Us"
          />
          <SidebarItem
            icon={() => <AnnouncementIcon />}
            to="/announcements"
            text="Announcements"
          />
          <SidebarItem
            to="https://stackoverflow.cariad.digital/"
            target="_blank"
            icon={() => <GroupsIcon />}
            aria-label="Community"
            text="Community"
          />
          <SidebarSubmenu
            kind={SUBMENU_OPTIONS.support}
            title={SUBMENU_TITLES.SUPPORT}
            Icon={HelpIcon}
            sidebarOptions={SUPPORT_SIDEBAR_OPTIONS}
            isHoveredOn={isSubmenuHoveredOn === SUBMENU_OPTIONS.support}
            submenuRef={supportSubmenuRef}
            popupOpen={submenuPopupOpen === SUBMENU_OPTIONS.support}
            onSetIsHoveredOn={setIsSubmenuHoveredOn}
            onHandleClick={handleClickSubmenu}
            onHandlePopupClickAway={handlePopupClickAwaySubmenu}
            onHandlePopupEnter={e =>
              handlePopupEnterSubmenu(e, SUBMENU_OPTIONS.support)
            }
            onHandlePopupLeave={handlePopupLeaveSubmenu}
          />
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
          <RequirePermission
            permission={devToolsAdministerPermission}
            errorPage={<></>}
          >
            <SidebarItem
              icon={() => <BuildIcon />}
              to="devtools"
              text="DevTools"
            />
          </RequirePermission>
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
