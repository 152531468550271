import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const catalogVer2Plugin = createPlugin({
  id: 'catalog-ver-2',
  routes: {
    root: rootRouteRef,
  },
});

export const CatalogVer2Page = catalogVer2Plugin.provide(
  createRoutableExtension({
    name: 'CatalogVer2Page',
    component: () => import('./components/Catalog').then(m => m.CatalogVer2),
    mountPoint: rootRouteRef,
  }),
);
